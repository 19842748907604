﻿@import '_variables.scss';

.dot {
    height: .85em;
    width: .85em;
    border-radius: 50%;
    display: inline-block;
}
.box-shadow-none {
    box-shadow:none;
}
.box-shadow:hover {
    box-shadow: $box-shadow;
}

.border-radius-round {
    border-radius: 16px;
}