﻿.hr-before:before {
    height: 1px;
    width: 96%;
    margin: 1em auto;
    background-color: #f2f4f7;
}

.hr-after:after {
    height: 1px;
    width: 96%;
    margin: 1em auto;
    margin-top: 3em;
    background-color: #f2f4f7;
}

a,
a:hover {
    cursor: pointer;
}

.solid-border {
    border-style: solid;
    border-width: 1px;
}

.currency:after {
    content: attr(data-after);
    display: inline-block;
    padding-left: 5px;
}
