﻿html {
    height: 100%;
    background-color: #f8f9fa;
}

body {
    position: relative;
    margin: 0;
    min-height: 100%;
    background-color: #f8f9fa !important;
}

.border-top-color {
    border-top-color: $grey-blue-dark;
}

.footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
}