﻿.image-fit-height {
    max-height: 100%;
    margin: auto;
    display: block;
}

.full-max-width {
    max-width: 100%;
}

.image-fade {
    opacity: 0.3;
}

.app-logo {
    height: 120%;
}

.brand-logo {
    height: 75px;
    max-height: 100%;
}
.image-border {
    border: 1px;
    border-style: solid;
    color: $grey-blue-lighter
}