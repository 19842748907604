﻿.margin-xs {
    margin: .25em;
}

.margin-sm {
    margin: .5em;
}

.margin-md {
    margin: 1em;
}

.margin-lg {
    margin: 1.5em;
}

.margin-xl {
    margin: 3em;
}

.margin-x-xs {
    margin: .25em 0;
}

.margin-x-sm {
    margin: .5em 0;
}

.margin-x-md {
    margin: 1em 0;
}

.margin-x-lg {
    margin: 1.5em 0;
}

.margin-x-xl {
    margin: 3em 0;
}

.margin-y-xs {
    margin: 0 .25em;
}

.margin-y-sm {
    margin: 0 .5em;
}

.margin-y-md {
    margin: 0 1em;
}

.margin-y-lg {
    margin: 0 1.5em;
}

.margin-y-xl {
    margin: 0 3em;
}

.margin-top-0 {
    margin-top: 0;
}

.margin-top-xs {
    margin-top: .25em;
}

.margin-top-sm {
    margin-top: .5em;
}

.margin-top-md {
    margin-top: 1em;
}

.margin-top-lg {
    margin-top: 1.5em;
}

.margin-top-xl {
    margin-top: 3em;
}

.margin-right-xs {
    margin-right: .25em;
}

.margin-right-sm {
    margin-right: .5em;
}

.margin-right-md {
    margin-right: 1em;
}

.margin-right-lg {
    margin-right: 1.5em;
}

.margin-right-xl {
    margin-right: 3em;
}

.margin-bottom-0 {
    margin-bottom: 0;
}

.margin-bottom-xs {
    margin-bottom: .25em;
}

.margin-bottom-sm {
    margin-bottom: .5em;
}

.margin-bottom-md {
    margin-bottom: 1em;
}

.margin-bottom-lg {
    margin-bottom: 1.5em;
}

.margin-bottom-xl {
    margin-bottom: 3em;
}

.margin-left-xs {
    margin-left: .25em;
}

.margin-left-sm {
    margin-left: .5em;
}

.margin-left-md {
    margin-left: 1em;
}

.margin-left-lg {
    margin-left: 1.5em;
}

.margin-left-xl {
    margin-left: 3em;
}