﻿.icon-container {
    position: relative;
    display: block;
}

@media (max-width: 970px) {
    .icon-container {
        width: 43px;
    }
}

.icon-size {
    font-size: 3.3em;
}

.icon-badge {
    position: absolute;
    right: 0;
    top: 0px;
}