﻿.loading-spinner {
    width: 25%;
    height: 25%;
    position: fixed;
    z-index: 999;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.loading-spinner-overlay {
    display: block;
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $transpant-background-color;
}
