﻿.btn-resize {
    width: 7em;
}

.btn-block {
    display: block;
    width: 100%;
    border: none;
    cursor: pointer;
    text-align: center;
}
