﻿.font-bold {
    font-weight: bold;
}

.font-bold-light {
    font-weight: 600;
}

.font-size-xs {
    font-size: 16px;
}

.text-underline{
    text-decoration: underline;
}

.text-no-underline,
.text-no-underline:hover {
    text-decoration: none;
}
.font-style-italic {
    font-style: italic;
}
.font-size-small {
    font-size: 10px !important;
}