﻿.navbar-collapse.in {
    overflow-y: visible;
}

.navbar-icon-link-secondary.open i,
.navbar-icon-link-primary.open i {
    color: $white;
}

.nav.dropdown-menu > li > a {
    cursor: pointer
}

.nav.dropdown-menu > li.active > a:hover {
    color: $white;
}

.navbar-brand {
    padding: 15px;
}